import { MeiliSearch } from "meilisearch";
import { useRuntimeConfig } from "#app";
import * as Sentry from "@sentry/vue";

export async function useMeilisearch(indexName: string, filter: any) {
  try {
    const config = useRuntimeConfig();
    const client = new MeiliSearch({
      host: config.public.meilisearchHost,
      apiKey: config.public.meilisearchKey,
    });
    const index = client.index(indexName);
    const query = await index.search("", filter);
    return query.hits;
  } catch (error: any) {
    if (
      error.statusCode === 499 ||
      error.message?.includes("Client Closed Request")
    ) {
      return []; // Return empty array, mostly Googlebot does it
    }

    Sentry.captureException(error, {
      extra: {
        file: "utils/meilisearch.ts",
        key: "ER-PI9Y",
        indexName,
        filter,
        error,
      },
    });
  }
}

export function useMeilisearchIndex(indexName: string) {
  try {
    const config = useRuntimeConfig();
    const client = new MeiliSearch({
      host: config.public.meilisearchHost,
      apiKey: config.public.meilisearchKey,
    });
    const index = client.index(indexName);
    return index;
  } catch (error: any) {
    if (
      error.statusCode === 499 ||
      error.message?.includes("Client Closed Request")
    ) {
      return null; // Return empty array, mostly Googlebot does it
    }
    Sentry.captureException(error, {
      extra: {
        file: "utils/meilisearch.ts",
        key: "ER-FD95",
        indexName,
        error,
      },
    });
  }
}
